import * as React from "react";
import { graphql, Link } from "gatsby";
import styled from "styled-components";

import BasicLayout from "../layouts/BasicLayout";
import Section from "../components/ui/section";
import Container from "../components/ui/container";
import PageHeader from "../components/ui/pageHeader";

const BoxContainer = styled.div`
  min-height: 200px;
  padding: 1em;
  min-width: 200px;
`;

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stage: 1,
      grade: "",
    };
    this.getGradeSubjects = this.getGradeSubjects.bind(this);
    this.handleGradeSubmit = this.handleGradeSubmit.bind(this);
    this.handleGoBack = this.handleGoBack.bind(this);
  }

  getGradeSubjects(products, subjects) {
    return [
      ...new Set(
        products
          .map((product) => {
            if (product.node.frontmatter.grade === this.state.grade) {
              return subjects.find(
                (subject) =>
                  subject.node.frontmatter.title ===
                  product.node.frontmatter.subject
              );
            }
          })
          .filter((val) => val !== undefined)
      ),
    ].sort((a, b) =>
      a.node.frontmatter.title.localeCompare(b.node.frontmatter.title)
    );
  }

  handleGradeSubmit(grade) {
    this.setState({
      grade: grade,
      stage: 2,
    });
  }

  handleGoBack() {
    this.setState({
      stage: this.state.stage - 1,
    });
  }

  findGradeImage(grade, gradesList) {
    console.log(gradesList);
    return gradesList.find((g) => g.node.frontmatter.title === grade)?.node
      .frontmatter.image;
  }

  render() {
    const { data } = this.props;
    const page = {
      title: data.page.frontmatter.title || "",
      image: data.page.frontmatter.image || "",
    };
    const subjects = data.subjects.edges;
    const products = data.products.edges;
    const gradesList = data.grades.edges;
    const grades = [
      ...new Set(products.map((product) => product.node.frontmatter.grade)),
    ].sort((a, b) => a.localeCompare(b));
    // if(this.state.stage === 2) console.log(this.getGradeSubjects(products, subjects));
    return (
      <div>
        <BasicLayout pageTitle={page.title}>
          <PageHeader
            title={page.title}
            image={page.image}
            breadcrumb={[{ page: page.title, path: this.props.uri }]}
          />
          <Section>
            <Container padding>
              {/* <h3 className="text-4xl text-center font-bold mb-10 text-gray-700">
                Published Products
              </h3> */}
              <div
                style={{ maxWidth: "1300px" }}
                className="flex flex-row flex-wrap gap-4 justify-center align-center content-center mx-auto"
              >
                {this.state.stage === 1 &&
                  grades.map((grade, i) =>
                    this.findGradeImage(grade, gradesList) ? (
                      <img
                        className="cursor-pointer"
                        width={"168px"}
                        height={"168px"}
                        key={i}
                        src={this.findGradeImage(grade, gradesList)}
                        alt={grade}
                        onClick={() => this.handleGradeSubmit(grade)}
                      />
                    ) : (
                      <BoxContainer
                        key={i}
                        className="my-5f flex flex-col bg-blue-100 cursor-pointer shadow-md rounded-lg text-blue-800 text-center hover:shadow-lg hover:text-blue-900"
                      >
                        <div
                          onClick={() => this.handleGradeSubmit(grade)}
                          className="flex flex-col flex-1 justify-center items-center "
                        >
                          <div className="break-words text-3xl">
                            <strong>{grade}</strong>
                          </div>
                        </div>
                      </BoxContainer>
                    )
                  )}
                {this.state.stage === 2 &&
                  this.getGradeSubjects(products, subjects).map(
                    (subject, i) => (
                      <BoxContainer
                        key={i}
                        className="my-5 flex flex-col bg-white shadow-md rounded-lg text-blue-800 text-center hover:shadow-lg hover:text-purple-700"
                      >
                        <Link
                          to={subject.node.fields.path}
                          className="flex flex-col flex-1 justify-center items-center "
                          key={i}
                        >
                          <img
                            style={{
                              maxWidth: "250px",
                              maxHeight: "250px",
                            }}
                            src={subject.node.frontmatter.image}
                            alt={subject.node.frontmatter.title}
                          />
                        </Link>
                      </BoxContainer>
                    )
                  )}
              </div>
            </Container>
            {this.state.stage > 1 && (
              <div className="flex flex-row justify-center">
                <span
                  onClick={() => this.handleGoBack()}
                  className={
                    "text-black mx-auto text-center font-bold py-2 px-4 bg-blue-400 cursor-pointer hover:bg-blue-600 rounded"
                  }
                >
                  Back
                </span>
              </div>
            )}
          </Section>
        </BasicLayout>
      </div>
    );
  }
}

export default Products;

export const ProductsQuery = graphql`
  query ProductsQuery {
    page: mdx(frontmatter: { id: { eq: "products" } }) {
      frontmatter {
        title
        image
      }
    }
    products: allMdx(filter: { frontmatter: { layout: { eq: "product" } } }) {
      edges {
        node {
          frontmatter {
            grade
            subject
          }
        }
      }
    }
    subjects: allMdx(filter: { frontmatter: { layout: { eq: "subject" } } }) {
      edges {
        node {
          frontmatter {
            title
            image
          }
          fields {
            path
          }
        }
      }
    }
    grades: allMdx(filter: { frontmatter: { layout: { eq: "grade" } } }) {
      edges {
        node {
          frontmatter {
            title
            full
            image
          }
        }
      }
    }
  }
`;
